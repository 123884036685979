import { callAadhaarVault } from "./citi.flux";

const getClientCustomData = async (document) => {
    if (document.client.includes("citi")) {
        return await callAadhaarVault(
            document.requestData
        );
    }
}

const generateDocuments = async (documents) => {
    const proofs = [];
    const proofData = {};
    const customDocuments = [];
    if(documents){
        for (let i = 0; i < documents.length; i++) {
            let item = documents[i];
            if (item.documentType == "custom") {
                proofs.push(item.documentName);
                customDocuments.push(item.documentName);
                proofData[item.documentName] = await getClientCustomData(item);
            } else {
                proofs.push(item.documentName);
                proofData[item.documentName] = item;
            }
    
            delete proofData[item.documentName].documentName;
        }
    }
    return {
        proofs,
        proofData,
        customDocuments
    }
}

const csvSafe = (data) => {
    if (typeof data == 'object') {
        if (data.length) {
            return data.join(",");
        }
        return "N/A";
    }
    return data;
}

const getHoomanDate = (epoch) => {
    try {
        const offset = (new Date().getTimezoneOffset() / 60) * -1;
        return new Date(epoch - - offset * 3600000);
    } catch (error) {
        return epoch;
    }
}

const formMisCustomDocumentData = (documentName, data) => {
    const dataToSend = {}
    for(let i in data) {
        dataToSend[`${documentName}_${i}`] = data[i];
    }
    return dataToSend;
}

const getMisData = (dataToSend, proofData, customDocuments, additionalData, consentData) => {
    let dummyBrowserData = { ...dataToSend["browserData"]}; 
    let deviceInfo;
    if (dummyBrowserData.deviceInfo) {
        if (dummyBrowserData.deviceInfo.is_mobile) {
            deviceInfo = dummyBrowserData.deviceInfo.complete_device_name;
        } else {
            deviceInfo = dummyBrowserData.deviceInfo.form_factor;
        }
    }
    delete dummyBrowserData.deviceInfo;
    let misData = {
        ...additionalData,
        ...dataToSend["geo"],
        ...dataToSend["faceMatchScore"],
        ...dummyBrowserData,
        deviceInfo,
        ...dataToSend["vcipAdminDetails"],
        ...dataToSend["forensicsData"],
        ...dataToSend["callDuration"],
        ...dataToSend["fieldValues"],
        ...consentData
    }

    if (customDocuments.length != 0) {
        customDocuments.forEach((document) => {
            misData = {
                ...misData,
                ...formMisCustomDocumentData(document, proofData[document].data)
            }
        });
    }

    //making JSON CSV safe
    for (let i in misData) {
        switch(i){
            case "startTime":
                misData[i] = getHoomanDate(misData[i]);
                break;
            case "endTime":
                misData[i] = getHoomanDate(misData[i]);
                break;
            case "consentTimestamp":
                misData[i] = parseInt(misData[i]);
                break;
            default:
                misData[i] = csvSafe(misData[i]);
        }

    }
    return misData;
}


export {
    generateDocuments,
    getMisData,
    getHoomanDate
}